<template>
  <div style="text-align: left">新增资讯</div>
  <el-form
    :model="addNewsForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="资讯标题" size="small" prop="title" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addNewsForm.title"
          clearable
          style="width: 230px"
          placeholder="请输入标题！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="关键词" prop="title" size="small" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="addNewsForm.keywords"
          clearable
          style="width: 230px"
          placeholder="请输入关键词！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="作者" size="small" prop="author" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="addNewsForm.author"
          clearable
          style="width: 230px"
          placeholder="请输入作者！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="显示时间"
      size="small"
      prop="showDate"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-date-picker
          v-model="addNewsForm.showDate"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </div>
    </el-form-item>
    <el-form-item
      label="资讯封面"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        :action="UploadUrl"
         :headers="{
           authorization: Token
         }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
         :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""
        :file-list="piclist"
        :on-success="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比11：8、图片大小200k为宜，只可设置一张图片,单张图片不超过2M</span
      >
    </el-form-item>
    <el-form-item label="摘要" size="small" prop="digest" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="addNewsForm.digest"
          clearable
          style="width: 230px"
          placeholder="请输入摘要！"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="是否展示" prop="adopted" style="width: 50%">
      <div class="displayflex" style="margin-top: 10px">
        <el-switch
          v-model="addNewsForm.adopted"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </el-form-item>
    <el-form-item label="详情" style="width: 80%">
      <vue-ueditor-wrap
        v-model="addNewsForm.content"
        :config="editorConfig"
        editor-id="newsInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
    <el-form-item label="附件" prop="goodsPic" style="width: 50%">
      <el-upload
        class="upload-demo"
        action="#"
        :auto-upload="false"
        :on-remove="handleRemove"
        multiple
        :limit="3"
        :file-list="attchFileList"
        :on-change="changeattchFileList"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>

    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: '',
      addNewsForm: {
        title: '',
        keywords: '',
        author: '',
        coverUrl: '',
        digest: '',
        content: '',
        attach1Url: '',
        attach2Url: '',
        attach3Url: '',
        showDate: '',
        adopted: true
      },
      baseUrl: '',

      rules: {
        ArtTitle: [
          { required: true, message: '请输入标题！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        ArtDesrc: [
          { required: true, message: '请填写文章简介！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        ArtDetail: [
          { required: true, message: '请输入文章内容！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        ArtProTime: [
          { required: true, message: '请输入时间！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      attchFileList: [],
      piclist: [],
      picformlist: [],
      attchsubmitList: [],
    }
  },
  computed: {
    News() {
      return this.$store.state.News
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  methods: {
        handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
      console.log('点击了')
      this.piclist = []
      this.picformlist = []
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    // },
    async filechange(res,file, fileList) {
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      console.log(res)
      this.piclist.push({
        url: this.imgBaseUrl + res.result
      })
      this.picformlist.push(res.result)
    },
    async changeattchFileList(file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      console.log(res)
      if (!(this.attchsubmitList.some(item => item === res.data.result))) {
        this.attchsubmitList.push(res.data.result)
      }
    },
    async onsubmit() {
      console.log(this.addNewsForm)
      console.log(this.attchsubmitList)
      console.log(this.picformlist)
      if (this.picformlist[0].trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      const data = {}
      if (this.$route.params.isadd === 'true') {
        data.title = this.addNewsForm.title
        data.keywords = this.addNewsForm.keywords
        data.content = this.addNewsForm.content
        data.author = this.addNewsForm.author
        data.coverUrl = this.picformlist[0]
        data.adopted = this.addNewsForm.adopted
        data.digest = this.addNewsForm.digest
        data.attach1Url = this.attchsubmitList[0] ? this.attchsubmitList[0] : ''
        data.attach2Url = this.attchsubmitList[1] ? this.attchsubmitList[1] : ''
        data.attach3Url = this.attchsubmitList[2] ? this.attchsubmitList[2] : ''
        data.showDate = this.formatdate(this.addNewsForm.showDate)
      } else {
        data.id = this.addNewsForm.id
        data.title = this.addNewsForm.title
        data.keywords = this.addNewsForm.keywords
        data.content = this.addNewsForm.content
        data.author = this.addNewsForm.author
        data.adopted = this.addNewsForm.adopted
        data.coverUrl = this.picformlist[0]
        data.homed = this.addNewsForm.homed
        data.top = this.addNewsForm.top
        data.bannered = this.addNewsForm.bannered
        data.digest = this.addNewsForm.digest
        data.attach1Url = this.attchsubmitList[0] ? this.attchsubmitList[0] : ''
        data.attach2Url = this.attchsubmitList[1] ? this.attchsubmitList[1] : ''
        data.attach3Url = this.attchsubmitList[2] ? this.attchsubmitList[2] : ''
        data.showDate = this.formatdate(this.addNewsForm.showDate)
      }
      console.log(data)
      const res = await this.$http.post('/nktdec/paperinfo/paperinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('信息设置成功！')
        this.$router.push('/newsinfo')
      }
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '资讯管理',
        index: '/newsmange'
      },
      {
        name: '资讯列表',
        index: '/newsinfo'
      }, {
        name: '编辑资讯',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    if (this.$route.params.isadd === 'false') {
      this.addNewsForm = this.News
      this.addNewsForm.showDate = new Date(this.addNewsForm.showDate)
      this.piclist.push({
        url: this.imgBaseUrl + this.News.coverUrl
      })
      this.picformlist = []
      this.picformlist.push(this.News.coverUrl)
      console.log(this.piclist)
      // this.teamlist.push({
      //   url: this.addNewsForm.pictureUrl1
      // })
      // this.piclist.push(this.addNewsForm.pictureUrl1)
      // if (this.addNewsForm.pictureUrl2) {
      //   this.teamlist.push({
      //     url: this.addNewsForm.pictureUrl2
      //   })
      //   this.piclist.push(this.addNewsForm.pictureUrl2)
      // }
      // if (this.addNewsForm.pictureUrl3) {
      //   this.teamlist.push({
      //     url: this.addNewsForm.pictureUrl3
      //   })
      //   this.piclist.push(this.addNewsForm.pictureUrl3)
      // }
    }
  },
}
</script>
<style scoped>
.upload-demo {
  text-align: left;
}
</style>
